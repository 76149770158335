// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datasets-js": () => import("./../src/pages/datasets.js" /* webpackChunkName: "component---src-pages-datasets-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("./../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-software-js": () => import("./../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-web-apps-js": () => import("./../src/pages/web-apps.js" /* webpackChunkName: "component---src-pages-web-apps-js" */),
  "component---src-templates-datasets-template-js": () => import("./../src/templates/DatasetsTemplate.js" /* webpackChunkName: "component---src-templates-datasets-template-js" */),
  "component---src-templates-software-template-js": () => import("./../src/templates/SoftwareTemplate.js" /* webpackChunkName: "component---src-templates-software-template-js" */),
  "component---src-templates-webapps-template-js": () => import("./../src/templates/WebappsTemplate.js" /* webpackChunkName: "component---src-templates-webapps-template-js" */)
}

